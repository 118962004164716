import React, { useState, useEffect } from "react";
import { Container, Grid, Skeleton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import * as assignmentService from "../../services/AssignmentService";
import * as caseStudyService from "../../services/CasestudyService";
import AssignmentCard from "./components/AssignmentCard";
import ConfirmDialog from "../../components/ConfirmDialog";
import Notification from "../../components/mui/Notification";
import PageHeader from "../../components/PageHeader";
import { STUDENT_ASSIGNMENT_GROUPS_ROUTE } from "../../helper/Constants";
import SearchBar from "./SearchBar";
import IndividualAssignmentCard from "./components/IndividualAssignmentCard";

export default function List() {
  const navigate = useNavigate();
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [assignments, setAssignments] = useState([]);

  useEffect(() => {
    assignmentService
      .getAll()
      .then((response) => {
        setAssignments(response.data);
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not connect to server. Please try again later.",
          type: "error",
        });
      })
      .finally(() => setLoading(false));
  }, [refresh]); // eslint-disable-line react-hooks/exhaustive-deps

  const onDelete = (id) => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: false,
    });
    assignmentService
      .del(id)
      .then(() => {
        const newList = assignments.filter((item) => item.id !== id);
        setAssignments(newList);
        setNotify({
          isOpen: true,
          message: "Deleted Successfully",
          type: "error",
        });
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        setNotify({
          isOpen: true,
          message: "Could not delete a project. Please try again later.",
          type: "error",
        });
      });
  };

  const handleDelete = (id) => {
    setConfirmDialog({
      isOpen: true,
      title: "Are you sure to delete this record?",
      subTitle: "You can't undo this operation",
      onConfirm: () => {
        onDelete(id);
      },
    });
  };

  const handleGroupList = (assignment, caseStudyId) => {
    navigate(STUDENT_ASSIGNMENT_GROUPS_ROUTE, {
      state: { assignment, caseStudyId },
    });
  };

  const onRefresh = () => {
    setRefresh(!refresh);
  };

  const handleSelection = (assignmentId) => {
    const isInList = assignments.some(
      (existingAssignment) => existingAssignment.id === assignmentId
    );
    if (!isInList) {
      assignmentService
        .enroll(assignmentId)
        .then((response) => {
          onRefresh();
        })
        .catch((e) => {
          console.error(`Error: ${e.message}`);
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        });
    } else {
      setNotify({
        isOpen: true,
        message: "You have already enrolled on this project",
        type: "warning",
      });
    }
  };

  const downloadPdf = (caseStudyId, title) => {
    caseStudyService
      .getPdf(caseStudyId)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "Case_study_" + title + ".pdf");
        document.body.appendChild(link);
        link.click();
      })
      .catch((e) => {
        console.error(`Error: ${e.message}`);
        if (e.response && e.response.status === 404) {
          setNotify({
            isOpen: true,
            message: "Could not find a pdf. Please try again later.",
            type: "warning",
          });
        } else {
          setNotify({
            isOpen: true,
            message: "Could not connect to server. Please try again later.",
            type: "error",
          });
        }
      });
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 2, mb: 2 }}>
      <PageHeader title="List of Projects" onRefresh={() => onRefresh()} />
      <SearchBar onSelect={handleSelection} sx={{ mb: 3, width: "80%" }} />
      <Grid container spacing={0}>
        {loading
          ? Array.from(new Array(3)).map((item, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{ m: 2 }}>
                <Skeleton variant="rectangular" height={118} />
                <Skeleton />
                <Skeleton width="60%" />
              </Grid>
            ))
          : assignments.map((assignment, index) => (
              <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                {assignment.type === "INDIVIDUAL" ? (
                  <IndividualAssignmentCard
                    assignment={assignment}
                    handleDelete={() => handleDelete(assignment.id)}
                    handleDownload={(assignmentTitle) =>
                      downloadPdf(assignment.caseStudyId, assignmentTitle)
                    }
                  />
                ) : (
                  <AssignmentCard
                    assignment={assignment}
                    handleDelete={() => handleDelete(assignment.id)}
                    handleGroupList={() =>
                      handleGroupList(assignment, assignment.caseStudyId)
                    }
                    handleDownload={(assignmentTitle) =>
                      downloadPdf(assignment.caseStudyId, assignmentTitle)
                    }
                  />
                )}
              </Grid>
            ))}
      </Grid>
      <Notification notify={notify} setNotify={setNotify} />
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </Container>
  );
}
