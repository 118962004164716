import { isEpmty } from "../helper/TextHelper";
import { httpClient } from "./RestService";

export const create = async (request) => {
  return await httpClient().post("/v1/education/instructor/casestudy", request);
};

export const upsertBaseInfo = async (request) => {
  if (isEpmty(request.id)) {
    return await httpClient().post(
      "/v1/education/instructor/casestudy/binf",
      request
    );
  } else {
    return await httpClient().put(
      "/v1/education/instructor/casestudy/binf",
      request
    );
  }
};

export const getAll = async (courseId) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/list/" + courseId
  );
};

export const getPurchaseDetails = async (id, assignmentId) => {
  return await httpClient().get(
    "/v1/education/casestudy/purchase/" + id + "/" + assignmentId
  );
};

export const getClientSecret = async (data) => {
  return httpClient().post("/v1/education/casestudy/purchase/cs", data);
};

export const getTeachingNotes = async (caseStudyId) => {
  return await httpClient(true).get(
    "/v1/education/instructor/casestudy/tn/" + caseStudyId
  );
};

export const getPdf = async (caseStudyId) => {
  return await httpClient(true).get(
    "/v1/education/instructor/casestudy/pdf/" + caseStudyId
  );
};

export const del = async (id) => {
  return await httpClient().delete("/v1/education/instructor/casestudy/" + id);
};

export const getIndustryTypes = async (field, country, province) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/industry/list?field=" +
      field +
      "&country=" +
      country +
      "&province=" +
      province
  );
};

export const getIndustryDescription = async (
  caseStudyId,
  industry,
  marketTerritory,
  concept,
  field,
  companyName,
  province
) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/industry/desc?caseStudyId=" +
      caseStudyId +
      "&industry=" +
      industry +
      "&marketTerritory=" +
      marketTerritory +
      "&concept=" +
      concept +
      "&field=" +
      field +
      "&companyName=" +
      companyName +
      "&province=" +
      province
  );
};

export const updateIndustryDescription = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/industry/desc",
    request
  );
};

export const getIndustryKeyTrends = async (
  caseStudyId,
  industry,
  marketTerritory,
  province,
  field,
  concept,
  companyName
) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/industry/trends?caseStudyId=" +
      caseStudyId +
      "&industry=" +
      industry +
      "&marketTerritory=" +
      marketTerritory +
      "&province=" +
      province +
      "&field=" +
      field +
      "&concept=" +
      concept +
      "&companyName=" +
      companyName
  );
};

export const updateIndustryKeyTrends = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/industry/trends",
    request
  );
};

export const getCompanyNames = async (
  industry,
  country,
  province,
  marketType
) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/company/list?industry=" +
      industry +
      "&country=" +
      country +
      "&province=" +
      province +
      "&marketType=" +
      marketType
  );
};

export const getConcept = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/concept",
    request
  );
};

export const getIndustryName = async (caseStudyId, companyName) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/industry/name?csId=" +
      caseStudyId +
      "&cName=" +
      companyName
  );
};

export const getCompanyBackground = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/company/bg",
    request
  );
};

export const updateCompanyBackground = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/company/bg",
    request
  );
};

export const getCompanyCompetitiveAdvantage = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/company/compadv",
    request
  );
};

export const updateCompetitiveAdvantage = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/company/compadv",
    request
  );
};

export const getMarketSegment = async (caseStudyId, companyName, country) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/company/mseg?companyName=" +
      companyName +
      "&country=" +
      country +
      "&caseStudyId=" +
      caseStudyId
  );
};

export const updateMarketSegment = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/company/mseg",
    request
  );
};

export const getProductAndServices = async (companyName, country, province) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/company/prodsvc?companyName=" +
      companyName +
      "&country=" +
      country +
      "&province=" +
      province
  );
};

export const getCompanyBrands = async (
  caseStudyId,
  companyName,
  marketTerritory,
  province
) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/company/brands?caseStudyId=" +
      caseStudyId +
      "&companyName=" +
      companyName +
      "&marketTerritory=" +
      marketTerritory +
      "&province=" +
      province
  );
};

export const updateBrands = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/company/brands",
    request
  );
};

export const getCompanyCompetitorsAnalysis = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/company/compAnalysis",
    request
  );
};

export const updateCompetitorsAnalysis = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/company/compAnalysis",
    request
  );
};

export const getTopics = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/topic/list",
    request
  );
};

export const getSubTopics = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/topic/sub",
    request
  );
};

export const getCompetitos = async (companyName, territory, chapterTitle) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/company/compts?companyName=" +
      companyName +
      "&marketTerritory=" +
      territory +
      "&chapterTitle=" +
      chapterTitle
  );
};

export const getBizFactors = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/biz/factors",
    request
  );
};

export const getMacroAnalysis = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/biz/macro/analysis",
    request
  );
};

export const updateMacroAnalysis = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/biz/macro/analysis",
    request
  );
};

export const getMicroAnalysis = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/biz/micro/analysis",
    request
  );
};

export const updateMicroAnalysis = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/biz/micro/analysis",
    request
  );
};

export const getTitleAndIntroduction = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/introTitle",
    request
  );
};

export const updateIntroduction = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/intro",
    request
  );
};

export const updateTitle = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/title",
    request
  );
};

export const createProblemStory = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/problem/story",
    request
  );
};

export const getProblemStory = async (caseStudyId) => {
  return await httpClient().get(
    "/v1/education/instructor/casestudy/problem/story?caseStudyId=" +
      caseStudyId
  );
};

export const updateProblemStory = async (request) => {
  console.log("updateProblemStory: ", request);
  return await httpClient().put(
    "/v1/education/instructor/casestudy/problem/story",
    request
  );
};

export const getQuestions = async (request) => {
  return await httpClient().post(
    "/v1/education/instructor/casestudy/qs",
    request
  );
};

export const updateQuestions = async (request) => {
  return await httpClient().put(
    "/v1/education/instructor/casestudy/qs",
    request
  );
};
